import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store";

Vue.use(VueRouter)

const routes = [

  // PUBLIC ROUTES

  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/public/LoginView.vue'),
    meta: {
      public: true,
      title: 'Login',
    },
  },

  // PRIVATE ROUTES

  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/private/DashboardView.vue'),
    meta: {
      public: false,
      title: 'Dashboard',
    },
  },
  {
    path: '/admins',
    name: 'Admins',
    component: () => import('@/views/private/AdminsView.vue'),
    meta: {
      public: false,
      title: 'Admins',
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/private/UsersView.vue'),
    meta: {
      public: false,
      title: 'Users',
    },
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: () => import('@/views/private/AccountsView.vue'),
    meta: {
      public: false,
      title: 'Accounts',
    },
  },
  {
    path: '/overview',
    name: 'Overview',
    component: () => import('@/views/private/OverviewView.vue'),
    meta: {
      public: false,
      owner: false,
      title: 'Overview',
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('@/views/private/ReportsView.vue'),
    meta: {
      public: false,
      owner: false,
      title: 'Reports',
    },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('@/views/private/ContactsView.vue'),
    meta: {
      public: false,
      owner: false,
      title: 'Contacts',
    },
  },
  {
    path: '/contact/:contactId',
    name: 'Contact',
    component: () => import('@/views/private/ContactView.vue'),
    props: function(route) {
      return {
        contactId: Number(route.params.contactId),
      };
    },
    meta: {
      public: false,
      owner: false,
      title: 'Contact',
    },
  },
  {
    path: '/contacts-import',
    name: 'Contacts Import',
    component: () => import('@/views/private/ContactsImportView.vue'),
    meta: {
      public: false,
      owner: false,
      title: 'Contacts Import',
    },
  },
  {
    path: '/contact-lists',
    name: 'Contact Lists',
    component: () => import('@/views/private/ContactListsView.vue'),
    meta: {
      public: false,
      owner: false,
      title: 'Contact Lists',
    },
  },
  {
    path: '/contact-list/:contactListId',
    name: 'Contact List',
    component: () => import('@/views/private/ContactListView.vue'),
    props: function(route) {
      return {
        contactListId: Number(route.params.contactListId),
      };
    },
    meta: {
      public: false,
      owner: false,
      title: 'Contact List',
    },
  },
  {
    path: '/contact-properties',
    name: 'Contact Properties',
    component: () => import('@/views/private/ContactPropertiesView.vue'),
    meta: {
      public: false,
      owner: false,
      title: 'Contact Properties',
    },
  },
  {
    path: '/companies',
    name: 'Companies',
    component: () => import('@/views/private/CompaniesView.vue'),
    meta: {
      public: false,
      owner: false,
      title: 'Companies',
    },
  },
  {
    path: '/company/:companyId',
    name: 'Company',
    component: () => import('@/views/private/CompanyView.vue'),
    props: function(route) {
      return {
        companyId: Number(route.params.companyId),
      };
    },
    meta: {
      public: false,
      owner: false,
      title: 'Company',
    },
  },
  {
    path: '/tags',
    name: 'Tags',
    component: () => import('@/views/private/TagsView.vue'),
    meta: {
      public: false,
      owner: false,
      title: 'Tags',
    },
  },
  {
    path: '/tag/:tagId',
    name: 'Tag',
    component: () => import('@/views/private/TagView.vue'),
    props: function(route) {
      return {
        tagId: Number(route.params.tagId),
      };
    },
    meta: {
      public: false,
      owner: false,
      title: 'Tag',
    },
  },
  {
    path: '/notes',
    name: 'Notes',
    component: () => import('@/views/private/NotesView.vue'),
    meta: {
      public: false,
      owner: false,
      title: 'Notes',
    },
  },
  {
    path: '/note/:noteId',
    name: 'Note',
    component: () => import('@/views/private/NoteView.vue'),
    props: function(route) {
      return {
        noteId: Number(route.params.noteId),
      };
    },
    meta: {
      public: false,
      owner: false,
      title: 'Note',
    },
  },
  {
    path: '/task-boards',
    name: 'Task Boards',
    component: () => import('@/views/private/TaskBoardsView.vue'),
    meta: {
      public: false,
      owner: false,
      title: 'Task Boards',
    },
  },
  {
    path: '/task-board/:taskBoardId',
    name: 'Task Board',
    component: () => import('@/views/private/TaskBoardView.vue'),
    props: function(route) {
      return {
        taskBoardId: Number(route.params.taskBoardId),
      };
    },
    meta: {
      public: false,
      owner: false,
      title: 'Task Board',
    },
  },
  {
    path: '/task-board-templates',
    name: 'Task Board Templates',
    component: () => import('@/views/private/TaskBoardTemplatesView.vue'),
    meta: {
      public: false,
      owner: false,
      title: 'Task Board Templates',
    },
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import('@/views/private/TasksView.vue'),
    meta: {
      public: false,
      owner: false,
      title: 'Tasks',
    },
  },
  {
    path: '/task/:taskId',
    name: 'Task',
    component: () => import('@/views/private/TaskView.vue'),
    props: function(route) {
      return {
        taskId: Number(route.params.taskId),
      };
    },
    meta: {
      public: false,
      owner: false,
      title: 'Task',
    },
  },
  {
    path: '/workflows',
    name: 'Workflows',
    component: () => import('@/views/private/WorkflowsView.vue'),
    meta: {
      public: false,
      owner: false,
      title: 'Workflows',
    },
  },
  {
    path: '/workflow/:workflowId',
    name: 'Workflow',
    component: () => import('@/views/private/WorkflowView.vue'),
    props: function(route) {
      return {
        workflowId: Number(route.params.workflowId),
      };
    },
    meta: {
      public: false,
      owner: false,
      title: 'Workflow',
    },
  },
  {
    path: '/workflow-templates',
    name: 'WorkflowTemplates',
    component: () => import('@/views/private/WorkflowTemplatesView.vue'),
    meta: {
      public: false,
      title: 'Workflow Templates',
    },
  },
  {
    path: '/workflow-template/:workflowTemplateId',
    name: 'Workflow Template',
    component: () => import('@/views/private/WorkflowTemplateView.vue'),
    props: function(route) {
      return {
        workflowTemplateId: Number(route.params.workflowTemplateId),
      };
    },
    meta: {
      public: false,
      title: 'Workflow Template',
    },
  },
  {
    path: '/schedules',
    name: 'Schedules',
    component: () => import('@/views/private/SchedulesView.vue'),
    meta: {
      public: false,
      owner: false,
      title: 'Schedules',
    },
  },
  {
    path: '/bot-servers',
    name: 'Bot Servers',
    component: () => import('@/views/private/BotServersView.vue'),
    meta: {
      public: false,
      title: 'Bot Servers',
    },
  },
  {
    path: '/bots/:botServerId',
    name: 'Bots (by Server ID)',
    component: () => import('@/views/private/BotsView.vue'),
    props: function(route) {
      return {
        botServerId: Number(route.params.botServerId),
      };
    },
    meta: {
      public: false,
      title: 'Bots',
    },
  },
  {
    path: '/bots',
    name: 'Bots',
    component: () => import('@/views/private/BotsView.vue'),
    meta: {
      public: false,
      title: 'Bots',
    },
  },
  {
    path: '/bot-queue/:botId',
    name: 'Bot Queue (by Bot ID)',
    component: () => import('@/views/private/BotQueueView.vue'),
    props: function(route) {
      return {
        botId: Number(route.params.botId),
      };
    },
    meta: {
      public: false,
      title: 'Bot Queue',
    },
  },
  {
    path: '/bot-queue',
    name: 'Bot Queue',
    component: () => import('@/views/private/BotQueueView.vue'),
    meta: {
      public: false,
      title: 'Bot Queue',
    },
  },
  {
    path: '/bot-errors/:botId',
    name: 'Bot Errors (by Bot ID)',
    component: () => import('@/views/private/BotErrorsView.vue'),
    props: function(route) {
      return {
        botId: Number(route.params.botId),
      };
    },
    meta: {
      public: false,
      title: 'Bot Errors',
    },
  },
  {
    path: '/bot-errors',
    name: 'Bot Errors',
    component: () => import('@/views/private/BotErrorsView.vue'),
    meta: {
      public: false,
      title: 'bot-errors',
    },
  },
  {
    path: '/bot-view/:botId',
    name: 'Bot View (by Bot ID)',
    component: () => import('@/views/private/BotView.vue'),
    props: function(route) {
      return {
        botId: Number(route.params.botId),
      };
    },
    meta: {
      public: false,
      title: 'Bot View',
    },
  },
  {
    path: '/bot-view',
    name: 'Bot View',
    component: () => import('@/views/private/BotView.vue'),
    meta: {
      public: false,
      title: 'Bot View',
    },
  },
  {
    path: '/bot-grid/:botServerId',
    name: 'Bot Grid (by Server ID)',
    component: () => import('@/views/private/BotGridView.vue'),
    props: function(route) {
      return {
        botServerId: Number(route.params.botServerId),
      };
    },
    meta: {
      public: false,
      title: 'Bot Grid',
    },
  },
  {
    path: '/bot-grid',
    name: 'Bot Grid',
    component: () => import('@/views/private/BotGridView.vue'),
    meta: {
      public: false,
      title: 'Bot Grid',
    },
  },
  {
    path: '/linkedin',
    name: 'LinkedIn',
    component: () => import('@/views/private/linkedin/LinkedinView.vue'),
    meta: {
      public: false,
      title: 'LinkedIn',
    },
  },
  {
    path: '/linkedin-profiles',
    name: 'LinkedIn Profiles',
    component: () => import('@/views/private/linkedin/LinkedinProfilesView.vue'),
    meta: {
      public: false,
      title: 'LinkedIn Profiles',
    },
  },
  {
    path: '/linkedin-conversations',
    name: 'LinkedIn Conversations',
    component: () => import('@/views/private/linkedin/LinkedinConversationsView.vue'),
    meta: {
      public: false,
      title: 'LinkedIn Conversations',
    },
  },
  {
    path: '/linkedin-conversation/:conversationId',
    name: 'LinkedIn Conversation',
    component: () => import('@/views/private/linkedin/LinkedinConversationView.vue'),
    props: function(route) {
      return {
        conversationId: Number(route.params.conversationId),
      };
    },
    meta: {
      public: false,
      title: 'LinkedIn Conversation',
    },
  },
  {
    path: '/linkedin-connections',
    name: 'LinkedIn Connections',
    component: () => import('@/views/private/linkedin/LinkedinConnectionsView.vue'),
    meta: {
      public: false,
      title: 'LinkedIn Connections',
    },
  },
  {
    path: '/linkedin-invitations-received',
    name: 'LinkedIn Invitations Received',
    component: () => import('@/views/private/linkedin/LinkedinInvitationsReceivedView.vue'),
    meta: {
      public: false,
      title: 'LinkedIn Invitations Received',
    },
  },
  {
    path: '/linkedin-invitations-sent',
    name: 'LinkedIn Invitations Sent',
    component: () => import('@/views/private/linkedin/LinkedinInvitationsSentView.vue'),
    meta: {
      public: false,
      title: 'LinkedIn Invitations Sent',
    },
  },
  {
    path: '/linkedin-suggestions',
    name: 'LinkedIn Suggestions',
    component: () => import('@/views/private/linkedin/LinkedinSuggestionsView.vue'),
    meta: {
      public: false,
      title: 'LinkedIn Suggestions',
    },
  },
  {
    path: '/linkedin-views',
    name: 'LinkedIn Views',
    component: () => import('@/views/private/linkedin/LinkedinViewsView.vue'),
    meta: {
      public: false,
      title: 'LinkedIn Views',
    },
  },
  {
    path: '/linkedin-searches',
    name: 'LinkedIn Searches',
    component: () => import('@/views/private/linkedin/LinkedinSearchesView.vue'),
    meta: {
      public: false,
      title: 'LinkedIn Searches',
    },
  },
  {
    path: '/linkedin-search/:searchId',
    name: 'LinkedIn Search',
    component: () => import('@/views/private/linkedin/LinkedinSearchView.vue'),
    props: function(route) {
      return {
        searchId: Number(route.params.searchId),
      };
    },
    meta: {
      public: false,
      title: 'LinkedIn Searches',
    },
  },
  {
    path: '/linkedin-groups',
    name: 'LinkedIn Groups',
    component: () => import('@/views/private/linkedin/LinkedinGroupsView.vue'),
    meta: {
      public: false,
      title: 'LinkedIn Groups',
    },
  },
  {
    path: '/linkedin-group/:linkedinGroupId',
    name: 'LinkedIn Group',
    component: () => import('@/views/private/linkedin/LinkedinGroupView.vue'),
    props: function(route) {
      return {
        linkedinGroupId: Number(route.params.linkedinGroupId),
      };
    },
    meta: {
      public: false,
      title: 'LinkedIn Group',
    },
  },
  {
    path: '/integrations',
    name: 'Integrations',
    component: () => import('@/views/private/integration/IntegrationsView.vue'),
    meta: {
      public: false,
      title: 'Integrations',
    },
  },
  {
    path: '/integrations/:integrationId',
    name: 'Integration',
    component: () => import('@/views/private/integration/IntegrationView.vue'),
    props: function(route) {
      return {
        integrationId: Number(route.params.integrationId),
      };
    },
    meta: {
      public: false,
      title: 'Integration',
    },
  },
  {
    path: '/webhooks',
    name: 'Webhooks',
    component: () => import('@/views/private/integration/WebhooksView.vue'),
    meta: {
      public: false,
      title: 'Webhooks',
    },
  },
  {
    path: '/webhooks/:webhookId',
    name: 'Webhook',
    component: () => import('@/views/private/integration/WebhookView.vue'),
    props: function(route) {
      return {
        webhookId: Number(route.params.webhookId),
      };
    },
    meta: {
      public: false,
      title: 'Webhook',
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/private/SettingsView.vue'),
    meta: {
      public: false,
      title: 'Settings',
    },
  },
  {
    path: '/sarah-walker/conversations',
    name: 'Sarah Walker Conversations',
    component: () => import('@/views/private/SarahWalkerConversationsView.vue'),
    meta: {
      public: false,
      title: 'Sarah Walker - Conversations',
    },
  },
  {
    path: '/sarah-walker/conversations/:conversationId',
    name: 'Sarah Walker [conversation]',
    component: () => import('@/views/private/SarahWalkerConversationsView.vue'),
    props: function(route) {
      return {
        conversationId: Number(route.params.conversationId),
      };
    },
    meta: {
      public: false,
      title: 'Sarah Walker - Conversation',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.public) {
    next()
  } else {
    if (store.getters.authenticated) {
      next()
    } else {
      next('/login')
    }
  }
})

router.afterEach((to) => {
  Vue.nextTick( () => {
    document.title = to.meta.title ? to.meta.title + ' | Webwalker Admin' : 'Webwalker Admin';
  });
})

export default router
