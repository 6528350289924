<template>
  <div>
    <v-navigation-drawer
      v-if="this.$store.getters.authenticated"
      v-model="sidebar.display"
      app
    >
      <v-list
        v-if="false"
        color="blue"
        class="darken-3"
        dark
      >
        <v-list-item class="px-2" link>
          <v-list-item-title class="title">
            {{ this.$store.state.admin.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
      >
        <v-list-item
          to="/"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
      >
        <v-list-group
          v-if="$store.getters.authenticated"
          prepend-icon="mdi-domain"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Accounts</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            to="/accounts"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Accounts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/users"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/admins"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-shield-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Admins</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
      >
        <v-list-group
          v-if="$store.getters.authenticated"
          prepend-icon="mdi-robot-angry"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Bots</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            to="/bot-servers"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-server</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Servers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/bots"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-robot</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Instances</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/bot-queue"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-format-list-checks</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Queue</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/bot-errors"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-bug</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Errors</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/bot-view"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-desktop-classic</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>View</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/bot-grid"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-view-grid</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Grid</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
      >
        <v-list-item
          to="/workflows"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-cogs</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Workflows</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-if="$store.getters.authenticated"
          prepend-icon="mdi-account-box-multiple"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Contacts</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            to="/contacts"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-box-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Contacts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/companies"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-briefcase</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Companies</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            to="/contact-lists"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-table-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Contact Lists</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/contacts-import"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-cloud-upload</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Import from File</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-if="$store.getters.authenticated"
          prepend-icon="mdi-bullseye-arrow"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>CRM</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            to="/task-boards"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-bullseye-arrow</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Tasks</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/notes"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-note</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Notes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/tags"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-tag</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Tags</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-if="$store.getters.authenticated"
          prepend-icon="mdi-linkedin"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>LinkedIn</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            to="/linkedin"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-box-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Accounts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            to="/linkedin-profiles"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-contacts</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Profiles</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            to="/linkedin-conversations"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-forum</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Conversations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            to="/linkedin-searches"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-search</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Searches</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            to="/linkedin-connections"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Connections</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            class="pl-2"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Invitations</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              to="/linkedin-invitations-received"
              class="pl-6"
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-account-multiple-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Received</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              to="/linkedin-invitations-sent"
              class="pl-6"
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-account-multiple-plus-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Sent</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            to="/linkedin-suggestions"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-question</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Suggestions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            to="/linkedin-views"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-target-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Views</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            to="/linkedin-groups"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Groups</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            class="pl-2"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Company Page</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              class="pl-6"
              link
              :disabled="true"
            >
              <v-list-item-icon>
                <v-icon>mdi-note-text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Posts</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="pl-6"
              link
              :disabled="true"
            >
              <v-list-item-icon>
                <v-icon>mdi-briefcase-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Invitations Sent</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-group>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
      >
        <v-list-group
          v-if="$store.getters.authenticated"
          prepend-icon="mdi-wrench"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Integrations</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            to="/integrations"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-api</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>API</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/webhooks"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-webhook</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Webhooks</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
      >
        <v-list-group
          v-if="$store.getters.authenticated"
          prepend-icon="mdi-chart-box"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Reports</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            to="/overview"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Overview</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/reports"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-chart-areaspline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Charts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-divider
        v-if="$store.getters.authenticated"
      ></v-divider>

      <v-list
        v-if="$store.getters.authenticated"
        nav
      >
        <v-list-item
          to="/sarah-walker/conversations"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-crown</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sarah Walker</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
      >
        <v-list-item
          to="/settings"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-tune</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

    </v-navigation-drawer>

    <v-app-bar
      color="blue"
      class="darken-3"
      dark
      elevation="0"
      app
      dense
      :absolute="!this.$store.getters.authenticated"
      :hide-on-scroll="!this.$store.getters.authenticated"
    >
      <v-app-bar-nav-icon
        v-if="this.$store.getters.authenticated"
        @click="sidebar.display = !sidebar.display"
      ></v-app-bar-nav-icon>

      <v-toolbar-title v-if="!smallMobileScreen420"></v-toolbar-title>

      <v-spacer></v-spacer>

      <main-nav-notifications
        v-if="$store.getters.authenticated"
      ></main-nav-notifications>

      <v-btn
        v-if="$store.getters.authenticated"
        to="/settings"
        link
        icon
        class="mx-1"
        small
      >
        <v-icon small>mdi-account-circle</v-icon>
      </v-btn>

      <div
        v-if="$store.getters.authenticated"
      >
        <v-tooltip v-if="$darkMode" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" small icon @click="$store.dispatch('switchDarkMode', false)">
              <v-icon small color="yellow" class="mr-1">mdi-moon-waxing-crescent</v-icon>
            </v-btn>
          </template>
          <span>Disable dark mode</span>
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" small icon @click="$store.dispatch('switchDarkMode', true)">
              <v-icon small color="yellow">mdi-white-balance-sunny</v-icon>
            </v-btn>
          </template>
          <span>Enable dark mode</span>
        </v-tooltip>
      </div>

      <v-divider
        v-if="$store.getters.authenticated"
        v-show="!this.isAuthView"
        vertical
        class="ml-2 mr-0 mr-sm-4"
      ></v-divider>

      <v-btn
        class="main-nav-btn mx-2"
        small
        text v-if="!this.$store.getters.authenticated"
        :to="{name: 'Login'}"
      >
        <v-icon class="mr-1" small>mdi-login</v-icon>
        Login
      </v-btn>
      <v-btn
        class="mx-2"
        icon
        small
        v-if="this.$store.getters.authenticated"
        @click="logoutConfirmation = true"
      >
        <v-icon small>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <base-dialog-confirmation
      v-model="logoutConfirmation"
      prompt="Do you really want to logout?"
      confirm-text="Logout"
      confirm-color="red"
      @confirm="logout"
      @cancel="logoutConfirmation = false"
    ></base-dialog-confirmation>
  </div>
</template>

<script>
import MainNavNotifications from '@/components/layout/MainNavNotifications.vue';

export default {
  name: 'MainNavSidebar',

  components: {
    MainNavNotifications,
  },

  data: () => ({
    logoutConfirmation: false,
    sidebar: {
      display: false,
    },
  }),

  computed: {
    isAuthView() {
      // Check if on auth pages (login, sign up, password reset, etc)
      if (this.$route && this.$route.meta) {
        return this.$route.meta.public;
      }
      return false;
    },
    smallMobileScreen420() {
      return window.screen.width < 420;
    },
  },

  methods: {
    logout: function () {
      this.$emit('logout');
    },
  },
};
</script>
